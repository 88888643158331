import { createSlice } from "@reduxjs/toolkit"
import {
    retrieveDashboardData,
    createPanel,
    retrievePanel,
    retrieveCommand,
    sendCommand,
    retrieveCharts,
    retrieveConfig,
    sendConfig,
    sendSmartFlowControl,
    retrieveSmartFlowControl
} from "./panelActions"

const initialState = {
    loading: {
        createPanel: false,
        getPanel: false,
        getCommand: false,
        postCommand: false,
        retrieveCharts: false,
        retrieveConfig: false,
        sendConfig: false,
        sendSmartFlowControl: false,
        retrieveSmartFlowControl: false
    },
    error: {
        createPanel: false,
        getPanel: false,
        dashboard: false,
        getCommand: false,
        postCommand: false,
        retrieveCharts: false,
        retrieveConfig: false,
        sendConfig: false,
        sendSmartFlowControl: false,
        retrieveSmartFlowControl: false,
    },
    installationAddress: null,
    installationDate: null,
    nominalCapacity: null,
    elapsedTime: null,
    soluctionBasicLevel: null,
    glycerolLevel: null,
    glycerolTemperature: null,
    glycerolPump: null,
    glycerolPh: null,
    luminosity: null,
    productionH2: null,
    gasFlow: null,
    temperature: null,
    h2: null,
    co2: null,
    page: null,
    pageCount: null,
    perPage: null,
    commandList: [],
    chartsTkGlycerolLevel: [],
    chartsTkGlycerolPump: [],
    chartsTkSoluctionBasicLevel: [],
    chartsProductionH2: [],
    chartsGasFlow: [],
    chartsTemperature: [],
    chartsPh: [],
    chartsTemperatureGlycerol: [],
    chartsLuminosity: [],
    id: null,
    phHigh: null,
    phLow: null,
    temperatureHigh: null,
    temperatureLow: null,
    reactorTemperatureHigh: null,
    reactorTemperatureLow: null,
    maxSyncTime: null,
    kd: null,
    kp: null,
    ki: null,
    algorithm: null,
    setPoint: null,
    active: null,
    success: false
}

const panelSlice = createSlice({
    name: 'panel',
    initialState,
    reducers: {
        resetDashboardState: (state) => {
            state.soluctionBasicLevel = null
            state.glycerolLevel = null
            state.glycerolTemperature = null
            state.glycerolPump = null
            state.glycerolPh = null
            state.luminosity = null
            state.productionH2 = null
            state.gasFlow = null
            state.temperature = null
            state.h2 = null
            state.co2 = null
        },
        resetPanelState: (state) => {
            state.installationAddress = null
            state.installationDate = null
            state.nominalCapacity = null
            state.elapsedTime = null
        },
        resetChartsState: (state) => {
            state.chartsTkGlycerolLevel = []
            state.chartsTkGlycerolPump = []
            state.chartsTkSoluctionBasicLevel = []
            state.chartsProductionH2 = []
            state.chartsGasFlow = []
            state.chartsTemperature = []
            state.chartsPh = []
            state.chartsTemperatureGlycerol = []
            state.chartsLuminosity = []
        },
        resetConfigState: (state) => {
            state.id = null
            state.phHigh = null
            state.phLow = null
            state.temperatureHigh = null
            state.temperatureLow = null
            state.reactorTemperatureHigh = null
            state.reactorTemperatureLow = null
            state.maxSyncTime = null
            state.kd = null
            state.kp = null
            state.ki = null
        },
        resetSuccessState: (state) => {
            state.success = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(retrieveDashboardData.pending, (state) => {
            })
            .addCase(retrieveDashboardData.fulfilled, (state, action) => {
                state.soluctionBasicLevel = action.payload.soluctionBasicLevel
                state.glycerolLevel = action.payload.glycerolLevel
                state.glycerolTemperature = action.payload.glycerolTemperature
                state.glycerolPump = action.payload.glycerolPump
                state.glycerolPh = action.payload.glycerolPh
                state.luminosity = action.payload.luminosity
                state.productionH2 = action.payload.productionH2
                state.gasFlow = action.payload.gasFlow
                state.temperature = action.payload.temperature
                state.h2 = action.payload.h2
                state.co2 = action.payload.co2
            })
            .addCase(retrieveDashboardData.rejected, (state) => {
                state.error.dashboard = true
            })
            .addCase(createPanel.pending, (state) => {
                state.loading.createPanel = true
            })
            .addCase(createPanel.fulfilled, (state) => {
                state.loading.createPanel = false
                state.success = true
            })
            .addCase(createPanel.rejected, (state) => {
                state.loading.createPanel = false
                state.error.createPanel = true
            })
            .addCase(retrievePanel.pending, (state) => {
                state.loading.getPanel = true
            })
            .addCase(retrievePanel.fulfilled, (state, action) => {
                state.loading.getPanel = false
                if (action.payload !== undefined) {
                    state.installationAddress = action.payload.installationAddress
                    state.installationDate = action.payload.installationDate
                    state.nominalCapacity = action.payload.nominalCapacity
                    state.elapsedTime = action.payload.elapsedTime
                }
            })
            .addCase(retrievePanel.rejected, (state) => {
                state.loading.getPanel = false
                state.error.getPanel = true
            })
            .addCase(retrieveCommand.pending, (state) => {
                state.loading.getCommand = true
            })
            .addCase(retrieveCommand.fulfilled, (state, action) => {
                state.loading.getCommand = false
                state.page = action.payload.page
                state.pageCount = action.payload.pageCount
                state.perPage = action.payload.perPage
                state.commandList = [...action.payload.commands]
            })
            .addCase(retrieveCommand.rejected, (state) => {
                state.loading.getCommand = false
                state.error.getCommand = true
            })
            .addCase(sendCommand.pending, (state) => {
                state.loading.postCommand = true
            })
            .addCase(sendCommand.fulfilled, (state) => {
                state.loading.postCommand = false
            })
            .addCase(sendCommand.rejected, (state) => {
                state.loading.postCommand = false
                state.error.postCommand = true
            })
            .addCase(retrieveCharts.pending, (state) => {
                state.loading.retrieveCharts = true
            })
            .addCase(retrieveCharts.fulfilled, (state, action) => {
                state.loading.retrieveCharts = false
                state.chartsTkGlycerolLevel = [...action.payload.tkGlycerolLevel]
                state.chartsTkGlycerolPump = [...action.payload.tkGlycerolPump]
                state.chartsTkSoluctionBasicLevel = [...action.payload.tkSoluctionBasicLevel]
                state.chartsProductionH2 = [...action.payload.productionH2]
                state.chartsGasFlow = [...action.payload.gasFlow]
                state.chartsTemperature = [...action.payload.temperature]
                state.chartsPh = [...action.payload.ph]
                state.chartsTemperatureGlycerol = [...action.payload.temperatureGlycerol]
                state.chartsLuminosity = [...action.payload.luminosity]
            })
            .addCase(retrieveCharts.rejected, (state) => {
                state.loading.retrieveCharts = false
                state.error.retrieveCharts = true
            })
            .addCase(retrieveConfig.pending, (state) => {
                state.loading.retrieveConfig = true
            })
            .addCase(retrieveConfig.fulfilled, (state, action) => {
                state.loading.retrieveConfig = false
                state.id = action.payload.id
                state.phHigh = action.payload.phHigh
                state.phLow = action.payload.phLow
                state.temperatureHigh = action.payload.temperatureHigh
                state.temperatureLow = action.payload.temperatureLow
                state.reactorTemperatureHigh = action.payload.reactorTemperatureHigh
                state.reactorTemperatureLow = action.payload.reactorTemperatureLow
                state.maxSyncTime = action.payload.maxSyncTime
                state.kd = action.payload.kd
                state.kp = action.payload.kp
                state.ki = action.payload.ki
            })
            .addCase(retrieveConfig.rejected, (state) => {
                state.loading.retrieveConfig = false
                state.error.retrieveConfig = true
            })
            .addCase(sendConfig.pending, (state) => {
                state.loading.sendConfig = true
            })
            .addCase(sendConfig.fulfilled, (state, action) => {
                state.loading.sendConfig = false
                state.id = action.payload.id
                state.phHigh = action.payload.phHigh
                state.phLow = action.payload.phLow
                state.temperatureHigh = action.payload.temperatureHigh
                state.temperatureLow = action.payload.temperatureLow
                state.reactorTemperatureHigh = action.payload.reactorTemperatureHigh
                state.reactorTemperatureLow = action.payload.reactorTemperatureLow
                state.maxSyncTime = action.payload.maxSyncTime
                state.kd = action.payload.kd
                state.kp = action.payload.kp
                state.ki = action.payload.ki
            })
            .addCase(sendConfig.rejected, (state) => {
                state.loading.sendConfig = false
                state.error.sendConfig = true
            })
            .addCase(sendSmartFlowControl.pending, (state) => {
                state.loading.sendSmartFlowControl = true
            })
            .addCase(sendSmartFlowControl.fulfilled, (state, action) => {
                state.loading.sendSmartFlowControl = false
            })
            .addCase(sendSmartFlowControl.rejected, (state) => {
                state.loading.sendSmartFlowControl = false
                state.error.sendSmartFlowControl = true
            })
            .addCase(retrieveSmartFlowControl.pending, (state) => {
                state.loading.retrieveSmartFlowControl = true
            })
            .addCase(retrieveSmartFlowControl.fulfilled, (state, action) => {
                state.loading.retrieveSmartFlowControl = false
                state.algorithm = action.payload.algorithm
                state.setPoint = action.payload.setPoint
                state.active = action.payload.active
            })
            .addCase(retrieveSmartFlowControl.rejected, (state) => {
                state.loading.retrieveSmartFlowControl = false
                state.error.retrieveSmartFlowControl = true
            })
    },
})

export const { resetDashboardState, resetPanelState, resetSuccessState } = panelSlice
export default panelSlice.reducer
