import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LineChart } from "../Charts/LineChart";

export const H2Production = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const { chartsProductionH2 } = useSelector((state) => state.panel);

  const d = new Date();
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const year = d.getFullYear();
  const date = `${day}/${month}/${year}`;

  return (
    <div
      className="flex flex-col bg-white mx-4 my-6 px-4 py-4 max-h-full rounded-lg"
      ref={ref}
    >
      <span className="font-bold text-lg sm:text-xl">{t("productionVolume")}</span>
      <div className="py-6 sm:py-10 h-full">
        <LineChart data={chartsProductionH2} />
      </div>
      <div className="hover:overflow-auto overflow-hidden max-h-64 sm:max-h-96 py-2">
        <table className="w-full sm:table-auto font-bold">
          <thead className="bg-gray-200 text-black">
            <tr>
              <th className="text-left py-2 sm:py-4 px-4 sm:px-6 text-sm sm:text-base">
                {t("date")}
              </th>
              <th className="text-center py-2 sm:py-4 px-4 sm:px-6 text-sm sm:text-base">
                {t("productionValue")}
              </th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {chartsProductionH2.map((item, index) => (
              <tr key={index}>
                <td className="text-left py-2 sm:py-4 px-4 sm:px-6 text-sm sm:text-base">
                  {date}
                </td>
                <td className="text-center py-2 sm:py-4 px-4 sm:px-6 text-sm sm:text-base">
                  {item}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
});
